import { z } from "zod";

export type Prettify<T> = {
  [K in keyof T]: T[K];
} & {};

export type Replace<T, R extends { [K in keyof T]?: any }> = Prettify<
  Omit<T, keyof R> & R
>;

export const convertPascalToDisplayNameWithSpaces = (text: string) => {
  return text.replace(/([A-Z])/g, " $1").trim();
};

export const convertCamelCaseToDisplayNameWithSpace = (text: string) => {
  if (!text.length) return text;
  const split = text.split("");
  split[0] = split[0].toUpperCase();
  return convertPascalToDisplayNameWithSpaces(split.join(""));
};

export const hcdErrorSchema = z.object({
  data: z.object({
    data: z.object({
      errors: z.array(
        z.object({ message: z.string(), property: z.string().optional() })
      ),
    }),
  }),
});

const jsErrorSchema = z.object({ message: z.string() });

export const getErrorMessagesFromError = (e: unknown): string[] => {
  const isAxiosError = hcdErrorSchema.safeParse(e);
  if (isAxiosError.success)
    return isAxiosError.data.data.data.errors.flatMap((x) => x.message);

  const isErrorWithMessage = jsErrorSchema.safeParse(e);
  if (isErrorWithMessage.success) return [isErrorWithMessage.data.message];

  const isNullishString = z.string().nullish().safeParse(e);
  if (isNullishString.success)
    return [isNullishString.data || "Unknown error occurred"];

  return ["Unknown error occurred"];
};

export const typeSafeObjectEntries = <T>(obj: T) => {
  return Object.entries(obj as Record<string, unknown>) as Entries<T>;
};

export const isValidIMONumber = (x: string) => {
  // https://en.wikipedia.org/wiki/IMO_number#Structure

  if (x.toLowerCase().startsWith("imo")) x = x.toLowerCase().replace("imo", "");

  x = x.trim();

  if (x.length !== 7) return false;

  const checkDigitShouldEndWith = Number(x[6]);
  const checkDigit = x
    .split("")
    .slice(0, -1)
    .reduce((prev, cur, i) => {
      return prev + Number(cur) * (7 - i);
    }, 0);

  return checkDigit.toString().endsWith(checkDigitShouldEndWith.toString());
};

type Entries<T> = { [K in keyof T]: [K, T[K]] }[keyof T][];

export const unNaNify = (value: number | undefined) =>
  isNaN(value as number) ? undefined : value;

export const chunkArrayInGroups = <T>(arr: T[], size: number) => {
  const result: T[][] = [];
  for (let i = 0; i < arr.length; i += size) {
    result.push(arr.slice(i, i + size));
  }
  return result;
};

export const isVowel = (s: string) => {
  return /^[aeiou]$/i.test(s);
};

export const isEmptyString = (s: string | null | undefined) => {
  if (s === null || s === undefined) return false;
  return s.length === 0;
};

export const getEnumValues = (x: Record<string, string | number>) =>
  Object.values(x).filter((v) => !isNaN(Number(v))) as number[];

export const getEnumNames = (x: Record<string, string | number>) =>
  Object.values(x).filter((v) => isNaN(Number(v))) as string[];

export const toTitleCase = (str: string) => {
  return str
    .split(" ")
    .map((w) => w[0].toUpperCase() + w.substring(1).toLowerCase())
    .join(" ");
};

export const hashString = (stringToHash: string) => {
  let hash = 0;
  for (let i = 0; i < stringToHash.length; i++) {
    const code = stringToHash.charCodeAt(i);
    hash = (hash << 5) - hash + code;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
};

export const makeArray = <T>(x: T | T[]) => {
  if (Array.isArray(x)) {
    return x.filter((x) => x);
  }

  return [x];
};

export const getLatest = <T>(results: T[], getter: (x: T) => string | Date) => {
  return results.reduce((latest: T | undefined, current) => {
    if (latest === undefined) {
      return current;
    }

    return new Date(getter(latest)) > new Date(getter(current))
      ? latest
      : current;
  }, undefined);
};

export const deeplyRemoveDuplicates = <T>(a: T[]) => {
  const seen: Record<string, boolean> = {};
  return a.filter((item) => {
    const json = JSON.stringify(item);
    return Object.prototype.hasOwnProperty.call(seen, json)
      ? false
      : (seen[json] = true);
  });
};

export const deeplyRemoveDuplicatesByKey = <T>(
  a: T[],
  key: (item: T) => string
) => {
  const seen: Record<string, boolean> = {};
  return a.filter((item) => {
    const k = key(item);
    return Object.prototype.hasOwnProperty.call(seen, k)
      ? false
      : (seen[k] = true);
  });
};

export const groupBy = <T, R extends number | string | symbol>(
  arr: T[],
  fn: (item: T) => R
) => {
  return arr.reduce<Record<R, T[]>>(
    (prev, curr) => {
      const groupKey = fn(curr);
      const group = prev[groupKey] || [];
      group.push(curr);
      return { ...prev, [groupKey]: group };
    },
    <Record<R, T[]>>{}
  );
};

export const sortAlphabetically = <T, R extends number | string>(
  arr: T[],
  fn: (item: T) => R
) => {
  return arr.sort((a, b) => {
    if (fn(a) < fn(b)) {
      return -1;
    }
    if (fn(a) > fn(b)) {
      return 1;
    }
    return 0;
  });
};

export const truncateLines = (input: string, maxLineLength: number) => {
  const lines = input.replace("\r\n", "\n").split("\n");
  const truncated = lines.map((x) => truncate(x, maxLineLength));
  if (truncated.length > 2) return [truncated[0], truncated[1], "…"];
  return truncated;
};
export const truncate = (input: string, maxLength: number) => {
  if (!input || input.length < maxLength - 1) {
    return input;
  }
  const beginning = input.substring(0, maxLength - 1);
  return beginning + "…";
};

export const getInitialsFromFullName = (x: string) => {
  return x
    .split(" ")
    .reduce((prev, curr) => {
      return prev + curr[0];
    }, "")
    .slice(0, 3)
    .toUpperCase();
};

export const getMockedPromise = <T>(
  x: T,
  randomlyReject = false,
  timeout = 1000
) =>
  new Promise<T>((resolve, reject) => {
    const maxVariance = 1000;
    const timeoutDelay = Math.random() * maxVariance + timeout;
    setTimeout(() => {
      if (randomlyReject && Math.random() > 0.5) {
        reject();
      } else {
        resolve(x);
      }
    }, timeoutDelay);
  });

export function roundTo(num: number, precision: number) {
  const factor = Math.pow(10, precision);
  return Math.round(num * factor) / factor;
}

export const newGUUID = () => crypto.randomUUID();

export const onlyUnique = (value: unknown, index: number, array: unknown[]) =>
  array.indexOf(value) === index;

export const countWords = (str: string) => str.trim().split(/\s+/).length;
