import { useUserStore } from "~/stores/userStore";
import type { RouteLocationNormalized } from "vue-router";
import { defineNuxtRouteMiddleware, navigateTo } from "#imports";

const isOpenRoute = (route: RouteLocationNormalized) => {
  return route.path.startsWith("/api") || route.path === "/";
};

export default defineNuxtRouteMiddleware(async (to, from) => {
  const store = useUserStore();
  const auth = await store.init();

  if (!auth.isLoggedIn && !isOpenRoute(to)) {
    console.info("Redirecting to login", auth, from.path, to.path);
    return navigateTo("/api/auth/login", { external: true });
  }
});
