<template>
  <q-icon data-testid="icon">
    <svg viewBox="0 0 56.62 56.94" xmlns="http://www.w3.org/2000/svg">
      <defs />
      <g id="Layer_2" data-name="Layer 2">
        <polygon
          class="cls-1"
          points="15.35 12.98 23.51 12.98 23.52 24.57 33.15 24.57 33.15 12.98 41.28 12.97 28.26 0 15.35 12.98"
        />
        <polygon
          class="cls-1"
          points="41.33 43.68 33.12 43.68 33.1 32.09 23.48 32.09 23.48 43.68 15.3 43.68 28.31 56.94 41.33 43.68"
        />
        <polygon class="cls-1" points="15.35 13 0 28.35 15.3 43.68 15.35 13" />
        <polygon
          class="cls-1"
          points="41.28 43.68 56.63 28.32 41.32 13 41.28 43.68"
        />
      </g>
    </svg>
  </q-icon>
</template>

<script setup lang="ts"></script>
