import { getErrorMessagesFromError } from "~/utils/helpers";
import { COMPANY_NAME } from "~/src/constants";
import { Notify } from "quasar";

const useNotify = () => {
  const notifyError = (
    message: unknown = "An error occurred",
    caption = `Please contact ${COMPANY_NAME}`,
    timeout = 0
  ) => {
    const _message =
      typeof message === "string"
        ? message
        : getErrorMessagesFromError(message).join("\n");

    Notify.create({
      message: _message,
      caption: caption,
      progress: true,
      timeout: timeout || 5000,
      color: "negative",
      icon: "error_outline",
      progressClass: "notifyProgress",
    });
  };

  const notifySuccess = (
    message = "Success",
    caption = "Operation Completed Successfully",
    timeout = 0
  ) => {
    Notify.create({
      message: message,
      caption: caption,
      progress: timeout === 0 ? false : true,
      timeout: timeout || 5000,
      color: "positive",
      icon: "done",
    });
  };
  const notifyInfo = (
    message = "Information",
    caption = "Information",
    timeout = 0
  ) => {
    Notify.create({
      message: message,
      caption: caption,
      progress: timeout === 0 ? false : true,
      timeout: timeout || 5000,
      color: "primary",
      icon: "info",
    });
  };

  return { notifyError, notifySuccess, notifyInfo };
};

export type Notifier = ReturnType<typeof useNotify>;
export default useNotify;
