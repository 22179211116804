<template>
  <div class="column flex items-center justify-center" style="height: 90vh">
    <Card class="column" style="width: 50em">
      <CardHeader icon="mdi-alert-circle">
        We have encountered an error
      </CardHeader>

      <q-card-section class="column gap-sm">
        <h4 v-if="error?.statusCode">Code: {{ error?.statusCode }}</h4>

        <h4 style="text-transform: unset">{{ error?.message }}</h4>
      </q-card-section>

      <q-card-section
        v-if="error?.stack && showStack"
        class="column gap-sm bg-green-3 q-ma-sm overflow-auto-x"
        style="border: 2px solid black; border-radius: 10px"
      >
        <code>Stack:</code>
        <div v-html="error.stack" />
      </q-card-section>

      <q-card-actions>
        <q-space />

        <q-btn
          label="Go Back"
          icon="mdi-step-backward"
          color="primary"
          flat
          @click="goBack()"
        />

        <q-btn
          :label="$t('general.home')"
          icon="mdi-home"
          color="primary"
          @click="handleGoHome()"
        />
      </q-card-actions>
    </Card>

    <div class="flex justify-center gap-md q-mt-sm">
      <Logo width="115px" variant="wsc" />
      <Logo width="160px" style="margin-bottom: -1px" variant="ncb-solac" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { NuxtError } from "nuxt/app";
import { clearError } from "#imports";
import useGoBack from "~/composables/useGoBack";

const goBack = useGoBack();

const props = defineProps({
  // eslint-disable-next-line vue/require-default-prop
  error: Object as () => NuxtError,
});

const showStack = process.env.NODE_ENV !== "production";

console.error(props.error);

const handleGoHome = () => clearError({ redirect: "/" });
</script>
