import { default as document_45authenticityGYGolkQKuAMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/document-authenticity.vue?macro=true";
import { default as historyTXZo9lWDPZMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/history.vue?macro=true";
import { default as booking8jbFZbKRSxMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/hits/booking.vue?macro=true";
import { default as containersvXyuCgCjIIMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/hits/containers.vue?macro=true";
import { default as partiesl4GZb7CljbMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/hits/parties.vue?macro=true";
import { default as sailingsB9qKsuJlc3Meta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/hits/sailings.vue?macro=true";
import { default as hitsCE6qha8tnmMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/hits.vue?macro=true";
import { default as inspections52uE8yaxn7Meta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/inspections.vue?macro=true";
import { default as risk_45summaryuSO5pTiLM0Meta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/risk-summary.vue?macro=true";
import { default as unusual_45behaviourAEpWQDCwRFMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/unusual-behaviour.vue?macro=true";
import { default as _91caseId_93KD7UbVCGFqMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId].vue?macro=true";
import { default as indexASaiMiqwidMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/index.vue?macro=true";
import { default as indexZ4p8Tl5os0Meta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/data-blocks/[id]/index.vue?macro=true";
import { default as indexYDuV3WRmjqMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/data-blocks/index.vue?macro=true";
import { default as _91id_93f1ZVNDY4rQMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/email-templates/[id].vue?macro=true";
import { default as indexdnODzFD1AYMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/email-templates/index.vue?macro=true";
import { default as indexnZZsGjsiGrMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/index.vue?macro=true";
import { default as _91id_93WybsLoI1GqMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/inspections/inspections/[id].vue?macro=true";
import { default as indexgsICGWttBIMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/inspections/inspections/index.vue?macro=true";
import { default as _91id_9310jtJ9gmuwMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/inspections/surveyors/[id].vue?macro=true";
import { default as indexvzTN0RpLHwMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/inspections/surveyors/index.vue?macro=true";
import { default as newHmbEEYl2SUMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/inspections/surveyors/new.vue?macro=true";
import { default as indexJR8ju5VyjAMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/libraries/[id]/index.vue?macro=true";
import { default as _91ruleId_93qNZfLHRQB7Meta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/libraries/[id]/rules/[ruleId].vue?macro=true";
import { default as indexADa7CMOE4fMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/libraries/index.vue?macro=true";
import { default as _91id_93Ax046Lle1GMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/policies/[id].vue?macro=true";
import { default as indexQwnUt5Veo0Meta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/policies/index.vue?macro=true";
import { default as _91id_93bp5WTNa6XbMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/service-hooks/[id].vue?macro=true";
import { default as indexu6owbbCfnDMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/service-hooks/index.vue?macro=true";
import { default as _91id_93kQjytKd83YMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/settings/config/[id].vue?macro=true";
import { default as indexfSvrdmmwrUMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/settings/config/index.vue?macro=true";
import { default as userSxt1faiXKmMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/settings/user.vue?macro=true";
import { default as settings7XYYhcfYoYMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/settings.vue?macro=true";
import { default as _91templateId_930OVw1c20GsMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/template-groups/[id]/[templateId].vue?macro=true";
import { default as indexUeKvQprD6oMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/template-groups/[id]/index.vue?macro=true";
import { default as indexojmFkutCLbMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/template-groups/index.vue?macro=true";
import { default as indexoeujk4XIElMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/verified-shippers/index.vue?macro=true";
export default [
  {
    name: _91caseId_93KD7UbVCGFqMeta?.name ?? "cases-caseId",
    path: _91caseId_93KD7UbVCGFqMeta?.path ?? "/cases/:caseId()",
    meta: _91caseId_93KD7UbVCGFqMeta || {},
    alias: _91caseId_93KD7UbVCGFqMeta?.alias || [],
    redirect: _91caseId_93KD7UbVCGFqMeta?.redirect,
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId].vue").then(m => m.default || m),
    children: [
  {
    name: document_45authenticityGYGolkQKuAMeta?.name ?? "cases-caseId-document-authenticity",
    path: document_45authenticityGYGolkQKuAMeta?.path ?? "document-authenticity",
    meta: document_45authenticityGYGolkQKuAMeta || {},
    alias: document_45authenticityGYGolkQKuAMeta?.alias || [],
    redirect: document_45authenticityGYGolkQKuAMeta?.redirect,
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/document-authenticity.vue").then(m => m.default || m)
  },
  {
    name: historyTXZo9lWDPZMeta?.name ?? "cases-caseId-history",
    path: historyTXZo9lWDPZMeta?.path ?? "history",
    meta: historyTXZo9lWDPZMeta || {},
    alias: historyTXZo9lWDPZMeta?.alias || [],
    redirect: historyTXZo9lWDPZMeta?.redirect,
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/history.vue").then(m => m.default || m)
  },
  {
    name: hitsCE6qha8tnmMeta?.name ?? "cases-caseId-hits",
    path: hitsCE6qha8tnmMeta?.path ?? "hits",
    meta: hitsCE6qha8tnmMeta || {},
    alias: hitsCE6qha8tnmMeta?.alias || [],
    redirect: hitsCE6qha8tnmMeta?.redirect,
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/hits.vue").then(m => m.default || m),
    children: [
  {
    name: booking8jbFZbKRSxMeta?.name ?? "cases-caseId-hits-booking",
    path: booking8jbFZbKRSxMeta?.path ?? "booking",
    meta: booking8jbFZbKRSxMeta || {},
    alias: booking8jbFZbKRSxMeta?.alias || [],
    redirect: booking8jbFZbKRSxMeta?.redirect,
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/hits/booking.vue").then(m => m.default || m)
  },
  {
    name: containersvXyuCgCjIIMeta?.name ?? "cases-caseId-hits-containers",
    path: containersvXyuCgCjIIMeta?.path ?? "containers",
    meta: containersvXyuCgCjIIMeta || {},
    alias: containersvXyuCgCjIIMeta?.alias || [],
    redirect: containersvXyuCgCjIIMeta?.redirect,
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/hits/containers.vue").then(m => m.default || m)
  },
  {
    name: partiesl4GZb7CljbMeta?.name ?? "cases-caseId-hits-parties",
    path: partiesl4GZb7CljbMeta?.path ?? "parties",
    meta: partiesl4GZb7CljbMeta || {},
    alias: partiesl4GZb7CljbMeta?.alias || [],
    redirect: partiesl4GZb7CljbMeta?.redirect,
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/hits/parties.vue").then(m => m.default || m)
  },
  {
    name: sailingsB9qKsuJlc3Meta?.name ?? "cases-caseId-hits-sailings",
    path: sailingsB9qKsuJlc3Meta?.path ?? "sailings",
    meta: sailingsB9qKsuJlc3Meta || {},
    alias: sailingsB9qKsuJlc3Meta?.alias || [],
    redirect: sailingsB9qKsuJlc3Meta?.redirect,
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/hits/sailings.vue").then(m => m.default || m)
  }
]
  },
  {
    name: inspections52uE8yaxn7Meta?.name ?? "cases-caseId-inspections",
    path: inspections52uE8yaxn7Meta?.path ?? "inspections",
    meta: inspections52uE8yaxn7Meta || {},
    alias: inspections52uE8yaxn7Meta?.alias || [],
    redirect: inspections52uE8yaxn7Meta?.redirect,
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/inspections.vue").then(m => m.default || m)
  },
  {
    name: risk_45summaryuSO5pTiLM0Meta?.name ?? "cases-caseId-risk-summary",
    path: risk_45summaryuSO5pTiLM0Meta?.path ?? "risk-summary",
    meta: risk_45summaryuSO5pTiLM0Meta || {},
    alias: risk_45summaryuSO5pTiLM0Meta?.alias || [],
    redirect: risk_45summaryuSO5pTiLM0Meta?.redirect,
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/risk-summary.vue").then(m => m.default || m)
  },
  {
    name: unusual_45behaviourAEpWQDCwRFMeta?.name ?? "cases-caseId-unusual-behaviour",
    path: unusual_45behaviourAEpWQDCwRFMeta?.path ?? "unusual-behaviour",
    meta: unusual_45behaviourAEpWQDCwRFMeta || {},
    alias: unusual_45behaviourAEpWQDCwRFMeta?.alias || [],
    redirect: unusual_45behaviourAEpWQDCwRFMeta?.redirect,
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/unusual-behaviour.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexASaiMiqwidMeta?.name ?? "cases",
    path: indexASaiMiqwidMeta?.path ?? "/cases",
    meta: indexASaiMiqwidMeta || {},
    alias: indexASaiMiqwidMeta?.alias || [],
    redirect: indexASaiMiqwidMeta?.redirect,
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ4p8Tl5os0Meta?.name ?? "data-blocks-id",
    path: indexZ4p8Tl5os0Meta?.path ?? "/data-blocks/:id()",
    meta: indexZ4p8Tl5os0Meta || {},
    alias: indexZ4p8Tl5os0Meta?.alias || [],
    redirect: indexZ4p8Tl5os0Meta?.redirect,
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/data-blocks/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexYDuV3WRmjqMeta?.name ?? "data-blocks",
    path: indexYDuV3WRmjqMeta?.path ?? "/data-blocks",
    meta: indexYDuV3WRmjqMeta || {},
    alias: indexYDuV3WRmjqMeta?.alias || [],
    redirect: indexYDuV3WRmjqMeta?.redirect,
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/data-blocks/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93f1ZVNDY4rQMeta?.name ?? "email-templates-id",
    path: _91id_93f1ZVNDY4rQMeta?.path ?? "/email-templates/:id()",
    meta: _91id_93f1ZVNDY4rQMeta || {},
    alias: _91id_93f1ZVNDY4rQMeta?.alias || [],
    redirect: _91id_93f1ZVNDY4rQMeta?.redirect,
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/email-templates/[id].vue").then(m => m.default || m)
  },
  {
    name: indexdnODzFD1AYMeta?.name ?? "email-templates",
    path: indexdnODzFD1AYMeta?.path ?? "/email-templates",
    meta: indexdnODzFD1AYMeta || {},
    alias: indexdnODzFD1AYMeta?.alias || [],
    redirect: indexdnODzFD1AYMeta?.redirect,
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/email-templates/index.vue").then(m => m.default || m)
  },
  {
    name: indexnZZsGjsiGrMeta?.name ?? "index",
    path: indexnZZsGjsiGrMeta?.path ?? "/",
    meta: indexnZZsGjsiGrMeta || {},
    alias: indexnZZsGjsiGrMeta?.alias || [],
    redirect: indexnZZsGjsiGrMeta?.redirect,
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93WybsLoI1GqMeta?.name ?? "inspections-inspections-id",
    path: _91id_93WybsLoI1GqMeta?.path ?? "/inspections/inspections/:id()",
    meta: _91id_93WybsLoI1GqMeta || {},
    alias: _91id_93WybsLoI1GqMeta?.alias || [],
    redirect: _91id_93WybsLoI1GqMeta?.redirect,
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/inspections/inspections/[id].vue").then(m => m.default || m)
  },
  {
    name: indexgsICGWttBIMeta?.name ?? "inspections-inspections",
    path: indexgsICGWttBIMeta?.path ?? "/inspections/inspections",
    meta: indexgsICGWttBIMeta || {},
    alias: indexgsICGWttBIMeta?.alias || [],
    redirect: indexgsICGWttBIMeta?.redirect,
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/inspections/inspections/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_9310jtJ9gmuwMeta?.name ?? "inspections-surveyors-id",
    path: _91id_9310jtJ9gmuwMeta?.path ?? "/inspections/surveyors/:id()",
    meta: _91id_9310jtJ9gmuwMeta || {},
    alias: _91id_9310jtJ9gmuwMeta?.alias || [],
    redirect: _91id_9310jtJ9gmuwMeta?.redirect,
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/inspections/surveyors/[id].vue").then(m => m.default || m)
  },
  {
    name: indexvzTN0RpLHwMeta?.name ?? "inspections-surveyors",
    path: indexvzTN0RpLHwMeta?.path ?? "/inspections/surveyors",
    meta: indexvzTN0RpLHwMeta || {},
    alias: indexvzTN0RpLHwMeta?.alias || [],
    redirect: indexvzTN0RpLHwMeta?.redirect,
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/inspections/surveyors/index.vue").then(m => m.default || m)
  },
  {
    name: newHmbEEYl2SUMeta?.name ?? "inspections-surveyors-new",
    path: newHmbEEYl2SUMeta?.path ?? "/inspections/surveyors/new",
    meta: newHmbEEYl2SUMeta || {},
    alias: newHmbEEYl2SUMeta?.alias || [],
    redirect: newHmbEEYl2SUMeta?.redirect,
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/inspections/surveyors/new.vue").then(m => m.default || m)
  },
  {
    name: indexJR8ju5VyjAMeta?.name ?? "libraries-id",
    path: indexJR8ju5VyjAMeta?.path ?? "/libraries/:id()",
    meta: indexJR8ju5VyjAMeta || {},
    alias: indexJR8ju5VyjAMeta?.alias || [],
    redirect: indexJR8ju5VyjAMeta?.redirect,
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/libraries/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91ruleId_93qNZfLHRQB7Meta?.name ?? "libraries-id-rules-ruleId",
    path: _91ruleId_93qNZfLHRQB7Meta?.path ?? "/libraries/:id()/rules/:ruleId()",
    meta: _91ruleId_93qNZfLHRQB7Meta || {},
    alias: _91ruleId_93qNZfLHRQB7Meta?.alias || [],
    redirect: _91ruleId_93qNZfLHRQB7Meta?.redirect,
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/libraries/[id]/rules/[ruleId].vue").then(m => m.default || m)
  },
  {
    name: indexADa7CMOE4fMeta?.name ?? "libraries",
    path: indexADa7CMOE4fMeta?.path ?? "/libraries",
    meta: indexADa7CMOE4fMeta || {},
    alias: indexADa7CMOE4fMeta?.alias || [],
    redirect: indexADa7CMOE4fMeta?.redirect,
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/libraries/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Ax046Lle1GMeta?.name ?? "policies-id",
    path: _91id_93Ax046Lle1GMeta?.path ?? "/policies/:id()",
    meta: _91id_93Ax046Lle1GMeta || {},
    alias: _91id_93Ax046Lle1GMeta?.alias || [],
    redirect: _91id_93Ax046Lle1GMeta?.redirect,
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/policies/[id].vue").then(m => m.default || m)
  },
  {
    name: indexQwnUt5Veo0Meta?.name ?? "policies",
    path: indexQwnUt5Veo0Meta?.path ?? "/policies",
    meta: indexQwnUt5Veo0Meta || {},
    alias: indexQwnUt5Veo0Meta?.alias || [],
    redirect: indexQwnUt5Veo0Meta?.redirect,
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/policies/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93bp5WTNa6XbMeta?.name ?? "service-hooks-id",
    path: _91id_93bp5WTNa6XbMeta?.path ?? "/service-hooks/:id()",
    meta: _91id_93bp5WTNa6XbMeta || {},
    alias: _91id_93bp5WTNa6XbMeta?.alias || [],
    redirect: _91id_93bp5WTNa6XbMeta?.redirect,
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/service-hooks/[id].vue").then(m => m.default || m)
  },
  {
    name: indexu6owbbCfnDMeta?.name ?? "service-hooks",
    path: indexu6owbbCfnDMeta?.path ?? "/service-hooks",
    meta: indexu6owbbCfnDMeta || {},
    alias: indexu6owbbCfnDMeta?.alias || [],
    redirect: indexu6owbbCfnDMeta?.redirect,
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/service-hooks/index.vue").then(m => m.default || m)
  },
  {
    name: settings7XYYhcfYoYMeta?.name ?? "settings",
    path: settings7XYYhcfYoYMeta?.path ?? "/settings",
    meta: settings7XYYhcfYoYMeta || {},
    alias: settings7XYYhcfYoYMeta?.alias || [],
    redirect: settings7XYYhcfYoYMeta?.redirect,
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93kQjytKd83YMeta?.name ?? "settings-config-id",
    path: _91id_93kQjytKd83YMeta?.path ?? "config/:id()",
    meta: _91id_93kQjytKd83YMeta || {},
    alias: _91id_93kQjytKd83YMeta?.alias || [],
    redirect: _91id_93kQjytKd83YMeta?.redirect,
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/settings/config/[id].vue").then(m => m.default || m)
  },
  {
    name: indexfSvrdmmwrUMeta?.name ?? "settings-config",
    path: indexfSvrdmmwrUMeta?.path ?? "config",
    meta: indexfSvrdmmwrUMeta || {},
    alias: indexfSvrdmmwrUMeta?.alias || [],
    redirect: indexfSvrdmmwrUMeta?.redirect,
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/settings/config/index.vue").then(m => m.default || m)
  },
  {
    name: userSxt1faiXKmMeta?.name ?? "settings-user",
    path: userSxt1faiXKmMeta?.path ?? "user",
    meta: userSxt1faiXKmMeta || {},
    alias: userSxt1faiXKmMeta?.alias || [],
    redirect: userSxt1faiXKmMeta?.redirect,
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/settings/user.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91templateId_930OVw1c20GsMeta?.name ?? "template-groups-id-templateId",
    path: _91templateId_930OVw1c20GsMeta?.path ?? "/template-groups/:id()/:templateId()",
    meta: _91templateId_930OVw1c20GsMeta || {},
    alias: _91templateId_930OVw1c20GsMeta?.alias || [],
    redirect: _91templateId_930OVw1c20GsMeta?.redirect,
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/template-groups/[id]/[templateId].vue").then(m => m.default || m)
  },
  {
    name: indexUeKvQprD6oMeta?.name ?? "template-groups-id",
    path: indexUeKvQprD6oMeta?.path ?? "/template-groups/:id()",
    meta: indexUeKvQprD6oMeta || {},
    alias: indexUeKvQprD6oMeta?.alias || [],
    redirect: indexUeKvQprD6oMeta?.redirect,
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/template-groups/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojmFkutCLbMeta?.name ?? "template-groups",
    path: indexojmFkutCLbMeta?.path ?? "/template-groups",
    meta: indexojmFkutCLbMeta || {},
    alias: indexojmFkutCLbMeta?.alias || [],
    redirect: indexojmFkutCLbMeta?.redirect,
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/template-groups/index.vue").then(m => m.default || m)
  },
  {
    name: indexoeujk4XIElMeta?.name ?? "verified-shippers",
    path: indexoeujk4XIElMeta?.path ?? "/verified-shippers",
    meta: indexoeujk4XIElMeta || {},
    alias: indexoeujk4XIElMeta?.alias || [],
    redirect: indexoeujk4XIElMeta?.redirect,
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/verified-shippers/index.vue").then(m => m.default || m)
  }
]