export const APP_NAME = "Hazcheck Detect" as const;
export const DEFAULT_PAGE_LIMIT = 10 as const;

export const COMPANY_NAME = "NCB Technologies" as const;
export const SUPPORT_EMAIL = "support@hazcheck.com" as const;

export const DEFAULT_TERMINAL_ICON = "mdi-home-group";
export const DEFAULT_BERTH_ICON = "mdi-home";

export const TEN_MINS_IN_MS = 600000;

export const EXIS_TEAM_ID = "1";

export const TABLE_DENSITIES = ["Default", "Comfortable", "Compact"] as const;
export type TableDensity = (typeof TABLE_DENSITIES)[number];

export const TOOLTIP_DELAY = 300;

export const UPDATE_AUTH_QUERY_KEY = "updateAuth" as const;
export const HARD_STOP_DISPLAY_CHAR = "X";

export const RT_BASE_URL = "/api/rt";
export const SOCKET_IO_BASE_URL = RT_BASE_URL + "/socket.io";
