import {
  definePayloadPlugin,
  definePayloadReducer,
  definePayloadReviver,
} from "#imports";
import {
  BookingCargoViewModel,
  BookingContainerViewModel,
  BookingSailingStageViewModel,
  BookingSailingViewModel,
  BookingViewModel,
} from "~/src/models/Case/Booking.viewmodel";

// https://nuxt.com/blog/v3-4#payload-enhancements
// https://github.com/rich-harris/devalue#custom-types

function defineBookingReducer(id: string, ctor: { new (...args: any[]): any }) {
  definePayloadReducer(
    id,
    (payload) =>
      payload instanceof ctor && [
        payload._raw,
        payload.index,
        payload.path.path,
      ]
  );
  definePayloadReviver(
    id,
    (payload) => new ctor(payload[0], payload[1], payload[2])
  );
}

export default definePayloadPlugin(() => {
  definePayloadReducer(
    "BookingViewModel",
    (payload) => payload instanceof BookingViewModel && payload._raw
  );
  definePayloadReviver(
    "BookingViewModel",
    (payload) => new BookingViewModel(payload)
  );

  defineBookingReducer("BookingCargoViewModel", BookingCargoViewModel);
  defineBookingReducer("BookingContainerViewModel", BookingContainerViewModel);
  defineBookingReducer("BookingSailingViewModel", BookingSailingViewModel);
  defineBookingReducer(
    "BookingSailingStageViewModel",
    BookingSailingStageViewModel
  );
});
